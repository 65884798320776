<template>
  <div class="lianluo" style="height: 100%">
    <div>
      <img src="../../assets/huayang/huayang2.png" alt="" style="width: 100%" />
    </div>
    <div>
      <div class="LL_center">
        <div class="LL_title" style="font-size: 20px">联系我们</div>
        <p>
          本栏目受理企业和个人在对产业集聚区办事过程中有关法规、政策、程序等问题的咨询。回复仅是一种指导性意见 ，不具有法定效力。不属于受理范围，且不适合公开的问题视为无效问题，管理人员有权对问题进行删改并不再告知。
        </p>
        <div class="LL_tel">
          <a :href="'tel:' + phone"><button>我 要 咨 询</button></a>
        </div>
      </div>
    </div>
    <!-- 底部 tab栏 -->
    <!-- <div>
      <van-tabbar v-model="active">
        <van-tabbar-item icon="home-o" to="/huayang/classroom"
          >知识大讲堂</van-tabbar-item
        >
        <van-tabbar-item icon="qr" to="/huayang/serve"
          >资源看板</van-tabbar-item
        >
        <van-tabbar-item icon="apps-o" to="/huayang">中介超市</van-tabbar-item>
        <van-tabbar-item icon="cluster-o" to="/huayang/interaction"
          >区企互动</van-tabbar-item
        >
        <van-tabbar-item icon="phone-circle-o" to="/huayang/lianluo"
          >联系我们</van-tabbar-item
        >
      </van-tabbar>
    </div> -->
  </div>
</template>
<script>
import { WeGetAppletInfo } from "@/api/index";
export default {
  data() {
    return {
      active: 4,
      phone:"",
    };
  },
  mounted(){
    this.getAppInfo();
  },
  methods: {
    // 小程序展示数据
    getAppInfo() {
      WeGetAppletInfo().then((res) => {
        var list = res.data;
        if (list.code == 0) {
          console.log(list);
          this.phone = list.data.TouchTel;
        }
      });
    },
  },
};
</script>
<style scoped>
.lianluo {
  /* background: url("../../assets/huayang/bg-fen.jpg") no-repeat center center; */
  background: #fafafa;
  background-size: 100%;
}
.LL_title {
  margin: 10px 0px;
  font-size: 22px;
}
.LL_center {
  width: 85%;
  font-size: 15px;
  background: #fff;
  border-radius: 15px;
  box-shadow: 1px 1px 5px #ccc;
  padding: 8px 15px;
  margin: 30px auto;
  line-height: 30px;
}
.LL_tel {
  margin: 0px 15px;
}
.LL_tel a {
  color: #fff;
}
.LL_tel button {
  width: 90%;
  height: 45px;
  margin: 10px 5%;
  background: #0188fb;
  border: none;
  border-radius: 8px;
}
</style>